<style>
.sticker-disabled {
  opacity: .1;
}
</style>

<template>
  <v-container fluid class="my-0 py-0">
    <v-window
      v-model="prepTaskWindow"
    >
      <v-window-item
        transition="none"
      >
        <template>
          <v-toolbar flat class="pb-10" :dense="denseView">
            <v-text-field
              class="d-print-none"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            />
            <v-spacer/>
            <v-btn-toggle
              v-model="search"
              dense
              rounded
            >
              <v-btn :value="null">All</v-btn>
              <v-btn
                v-for="team of teamFilter"
                v-bind:key="team"
                :value="`team:${team}`"
              >{{ team }}
              </v-btn>

            </v-btn-toggle>

            <v-spacer/>
            <v-text-field :value="datesFormatted"
                          persistent-hint
                          hint="Meal Dates"
                          label="Select dates"
                          single-line
                          readonly
                          @click="datePickerDate=dates[0]; showDatePicker=true"
                          append-icon="mdi-calendar"
            />
            <v-dialog
              v-model="showDatePicker"
              ref="dialog"
              width="290px"
              persistent
            >
              <v-date-picker
                v-model="datePickerDate"
                no-title
                :allowed-dates="allowedDates"
                @close="closeDatePicker"
              ></v-date-picker>
              <v-btn @click="closeDatePicker">Close</v-btn>
            </v-dialog>
          </v-toolbar>
          <v-toolbar flat v-if="!hideGarnish" :dense="denseView" class="ma-0 pa-0">
            <v-checkbox
              label="Show Garnish"
              v-model="showGarnish"
              dense
              hide-details
            />
            <v-checkbox
              class="pl-4"
              label="Only Garnish"
              v-model="onlyGarnish"
              dense
              hide-details
            />
            <v-spacer/>
            <template v-if="totalTimeEstimate">
              <v-chip outlined>est. {{ formatTimeDuration(totalTimeEstimate) }} ({{tasksWithTimeEstimate.length}} tasks)</v-chip>
              <v-spacer/>
            </template>
            <v-checkbox
              class="pl-4"
              label="Dense View"
              v-model="denseView"
              dense
              hide-details
            />
          </v-toolbar>
          <TaskPanel
            v-if="hasDates"
            :team="team"
            :dates="getDatesInRange(dateFrom,dateTo)"
            :search="search"
            :filter="filter"
            :show-late-warnings="showLateWarnings"
            :late-warning-priority-deadlines="lateWarningPriorityDeadlines"
            :sort-fn="sortFn"
            v-on:print-stickers="printStickers($event)"
            :dense-view="denseView"
            :filter-fn="taskFilter"
            v-on:update:tasks="tasks = $event"
          >
            <template v-slot:task-title="{ props: { task}}">
              <v-card-title>
                <v-row style="word-break: keep-all">
                  <v-col
                    align-self="center"
                    :style="denseView ? 'text-wrap: nowrap; overflow: clip; text-overflow: ellipsis':''"
                  >
                    <template v-if="denseView">
                      <v-btn icon class="green" v-if="(!task.isStarted && !task.isPaused) || task.complete">
                        <v-icon>mdi-play</v-icon>
                      </v-btn>
                      <v-btn icon class="yellow" v-if="!task.isStarted && task.isPaused && !task.complete">
                        <v-icon>mdi-pause</v-icon>
                      </v-btn>
                      <v-btn icon class="red" v-if="task.isStarted">
                        <v-icon>mdi-stop</v-icon>
                      </v-btn>
                    </template>
                    <span class="pl-1 font-weight-bold" v-if="task.source && task.source.batch">
                      [{{task.source.batch}}]
                    </span>
                    <span>{{ removeBatch(task.title) }}</span>
                  </v-col>
                  <v-col cols="3" class="text-right">
                    <div v-html="formatTaskAmount(task)"></div>
                    <br v-if="!denseView"/>
                    <v-icon color="black" v-if="task.complete">mdi-check</v-icon>
                  </v-col>
                </v-row>
              </v-card-title>
            </template>
            <!--      /* eslint-disable-next-line vue/no-unused-vars */-->
            <template v-slot:task-text="{ props: {}}">
              <div></div>
            </template>
            <template v-slot:task-confirm-extra="{ props: { confirmTask } }">
              <v-card-actions v-if="!showUpdateEstimate">
                <span v-if="canSetEstimate || confirmTask.source.timeEstimate">Estimate: {{ confirmTask.source.timeEstimate  || '?' }} minutes to process {{ formatWeightWithUnits(confirmTask.source.rawTotal )}}</span>
                <v-spacer/>
                <v-btn v-if="canSetEstimate" @click="showUpdateEstimate=true">Change Estimate</v-btn>
              </v-card-actions>
              <v-card-actions v-if="showUpdateEstimate">
                Time Estimate
                <v-text-field
                  class="ml-2"
                  :label="`minutes to process ${confirmTask.source.rawTotal ? formatWeightWithUnits(confirmTask.source.rawTotal):''}`"
                  v-model="updateTimeEstimate"
                  type="number"
                />
                <v-btn class="ml-2" @click="saveUpdatedEstimate(confirmTask, updateTimeEstimate)">Save</v-btn>
                <v-spacer/>
                <v-btn @click="showUpdateEstimate=false">Cancel</v-btn>
              </v-card-actions>
            </template>
          </TaskPanel>
          <v-alert v-if="!hasDates">
            Please select a production date
          </v-alert>
        </template>
      </v-window-item>
      <v-window-item transition="none">
        <template v-if="showStickers">
          <v-toolbar class="d-print-none" flat>
            <v-btn :loading="isPrinting" @click="closeStickers">Close</v-btn>
            <template v-if="hasPrint">
              <v-spacer/>
              <!--              <v-btn x-large @click="doPrint">Print All</v-btn>-->
              <v-spacer/>
              <v-btn :loading="isPrinting" @click="closeStickers">Close</v-btn>
            </template>
          </v-toolbar>
          <v-row dense v-for="(count,mealId) of printTask.source.counts" v-bind:key="mealId" class="">
            <v-col cols="12" class="d-print-none text-center" align-self="center">
              <h2>Rack {{ count.rack }} - {{ count.key }}</h2>
            </v-col>
            <template v-if="printTask.source.counts[mealId].totalAmountMain">
              <v-col :class="stickerClass[`${mealId}-main`]">
                <v-row class="d-print-none">
                  <v-col cols="" class=" text-center" align-self="center">
                    <v-btn x-large color="primary" :loading="isPrinting" @click="printOnly(mealId,'main')">Print Main
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="text-left ">
                    <ComponentLabel
                      class=""
                      :rack="getMainRack(count)"
                      :title="`${count.key}`"
                      :code="mealId"
                      :text="`${printTask.source.component.name}`"
                      :production_priority="printTask.source.production_priority"
                      :batch="printTask.source.batch"
                      :amount="printTask.source.counts[mealId].totalAmountMain || 0"
                      :date="formatDateRange(dateFrom,dateTo)"
                      :portion-size="count.portionSize"
                      :portion-count="subtractCount(count.count,count.allergyCount)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </template>
            <template v-if="printTask.source.counts[mealId].totalAmountAllergy">
              <v-col :class="stickerClass[`${mealId}-allergy`]">
                <v-row>
                  <v-col cols="" class="d-print-none text-center" align-self="center">
                    <v-btn x-large color="secondary" :loading="isPrinting" @click="printOnly(mealId,'allergy')">Print
                      Allergy
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                  >
                    <ComponentLabel
                      :rack="count.rack?`A${count.rack}`:''"
                      :title="`${count.key}`"
                      :code="mealId"
                      :text="`${printTask.source.component.name}`"
                      :production_priority="printTask.source.production_priority"
                      :batch="printTask.source.batch"
                      :amount="printTask.source.counts[mealId].totalAmountAllergy"
                      line="AL"
                      :date="formatDateRange(dateFrom,dateTo)"
                      :portion-size="count.portionSize"
                      :portion-count="subtractCount(count.allergyCount, count.substituteCount)"
                    />
                  </v-col>
                </v-row>
              </v-col>

            </template>
            <template v-if="printTask.source.counts[mealId].totalAmountSub">
              <v-col :class="stickerClass[`${mealId}-sub`]">
                <v-row>
                  <v-col class="d-print-none text-center" align-self="center">
                    <v-btn x-large color="warning" :loading="isPrinting" @click="printOnly(mealId,'sub')">Print Sub
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ComponentLabel
                      :rack="count.rack?`A${count.rack}`:''"
                      :title="`${count.key}`"
                      :code="mealId"
                      :text="`SUB for ${printTask.source.component.name}`"
                      :production_priority="printTask.source.production_priority"
                      :batch="printTask.source.batch"
                      :amount="printTask.source.counts[mealId].totalAmountSub"
                      line="S"
                      line-shape="square"
                      :date="formatDateRange(dateFrom,dateTo)"
                      :portion-size="count.portionSize"
                      :portion-count="count.substituteCount"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>
        </template>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {dateFormatted, extractFoodId, formatWeight, getDatesInRange, getProductionDays} from '@/store/utils';
import TaskPanel from '@/components/tasks/TaskPanel';
import moment from 'moment';
import urlState from "@/router/urlState";
import ComponentLabel from "@/components/ComponentLabel.vue";
import api from "@/api";

export default {
  name: "PrepTasks",
  components: {ComponentLabel, TaskPanel},
  mixins: [urlState],

  mounted() {
    this.syncToUrl({
      param: 'taskFilter', urlParam: 'status', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showAllergy', urlParam: 'allergy', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'denseView', urlParam: 'dense', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showGarnish', urlParam: 'garnish', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'onlyGarnish', urlParam: 'garnish_only', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
  },
  computed: {
    ...mapGetters(['getComponent', 'getMeal',]),
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    hasDates() {
      return this.dates.length > 0;
    },
    filter() {
      return this.showGarnish ? '' : 'garnish';
    },
    tasksWithTimeEstimate() {
      return this.tasks.filter(t => t.source.timeEstimate)
    },
    totalTimeEstimate() {
      return this.tasksWithTimeEstimate.reduce((sum, t) => t.source.timeEstimate + sum, 0);
    }
  },

  methods: {
    getDatesInRange,
    extractFoodId,
    getComponents(componentIds) {
      return componentIds.map(this.getComponent).sort((a, b) => a.name.localeCompare(b.name));
    },
    getTimerId(id) {
      return `I${id}`;
    },
    dateFormatted,
    closeDatePicker() {
      this.showDatePicker = false;
      this.dates = getProductionDays(this.datePickerDate);
      console.log('setting dates', this.dates);
      return this.fetchData();
    },
    fetchData() {
      this.error = this.post = null
      this.loading = true
    },
    formatTaskAmount(task) {
      if (task.source) {
        const {source: {totalAmount, cookedTotal, rawTotal}} = task;
        if (cookedTotal && cookedTotal === rawTotal) {
          return this.formatWeightWithUnits(cookedTotal);
        } else if (cookedTotal) {
          return `${this.formatWeightWithUnits(cookedTotal)}<br/>raw ${this.formatWeightWithUnits(rawTotal)}`;
        } else if (totalAmount) {
          return this.formatWeightWithUnits(totalAmount);
        } else {
          return this.formatWeightWithUnits(0)
        }
      }
    },
    formatWeightWithUnits(amount) {
      const units = ['g', 'kg'];
      const decimalPlaces = [1, 2];
      return formatWeight(amount, units, decimalPlaces);
    },
    allowedDates(val) {
      const notAllowed = {
        0: true,
        // 2: true,
        // 4: true
      };

      return !notAllowed[moment(val).day()];
    },
    printStickers(task) {
      this.printTask = task;
      this.showStickers = true;
    },
    hasPrint() {
      return window.electronAPI && window.electronAPI.print
    },
    async doPrint() {
      try {
        if (window.electronAPI) {
          const result = await window.electronAPI.print({
            pageSize: {
              // height: 352 * 150,
              // width: 352 * 300,
              width: 352 * 150,
              height: 352 * 300,
            },
            // landscape: true,
            scaleFactor: 90,
            margins: {
              marginType: 'none'
            },
            silent: true,
            // NOTE: this does not appear to work!  :/
            pageRanges: [{from: 0, to: 0}],
          });
          console.log('printed', result);
        }
      } catch (e) {
        console.error('electron print fail', e);
      }
    },
    formatDateRange(dateFrom, dateTo) {
      return `${moment(dateFrom).format('ddd D')} to ${moment(dateTo).format('ddd D MMM')} `
    },
    closeStickers() {
      this.showStickers = false;
      const taskId = this.$route.query.sticker;
      const query = {...this.$route.query, task: taskId, sticker: null};
      this.$router.replace({query})
    },
    async printOnly(mealId, type) {
      this.isPrinting = true;
      console.log('selecting', this.selectedToPrint);
      const dPrintNoneStickerDisabled = 'd-print-none sticker-disabled';
      Object.keys(this.printTask.source.counts).forEach(mealId => {
        this.$set(this.stickerClass, `${mealId}-main`, dPrintNoneStickerDisabled);
        this.$set(this.stickerClass, `${mealId}-allergy`, dPrintNoneStickerDisabled);
        this.$set(this.stickerClass, `${mealId}-sub`, dPrintNoneStickerDisabled);
      });
      this.selectedToPrint = `${mealId}-${type}`;
      this.$set(this.stickerClass, this.selectedToPrint, '');
      setTimeout(async () => {
        await this.doPrint();
        setTimeout(() => {
          console.log('unselecting', this.selectedToPrint);
          this.selectedToPrint = false;
          this.stickerClass = {};
          this.isPrinting = false;
        }, 1000);
      }, 1000);
    },
    getMainRack(count) {
      if (count.key === 'CC' && count.rack === 'CC') {
        return '';
      }
      return count.rack ? `M${count.rack}` : ''
    },
    removeBatch(str) {
      return str.replace(/\[(.*)]/g, '');
    },
    subtractCount(countA = {}, countB = {}) {
      const result = {...countA};
      Object.keys(countB)
        .forEach(key => {
          result[key] = (countA[key] || 0) - countB[key]
        });
      return result;
    },
    taskFilter(t) {
      if (this.shortsOnly) {
        return t.source && t.source.isShort;
      }
      if (this.hideShorts) {
        return !(t.source && t.source.isShort)
      }
      return true;

    },
    saveUpdatedEstimate(task, updateTimeEstimate) {
      updateTimeEstimate = updateTimeEstimate && Number(updateTimeEstimate);
      if (this.team === 'chopping') {
        console.log('saveUpdatedEstimate', updateTimeEstimate, task)
        this.showUpdateEstimate = false;
        const {foodid, rawTotal} = task.source;
        const minutes_per_kg = Number(((updateTimeEstimate / rawTotal) * 1000).toFixed(2));
        console.log('saving new estimate', {foodid, updateTimeEstimate, rawTotal, minutes_per_kg});
        api.put('/v2/food/chopping-estimate/' + foodid, {minutes_per_kg});
        task.source.timeEstimate = updateTimeEstimate;
        this.$socket.emit('task:update', task);
        this.$socket.emit('tasks:cache:clear', { team: this.team});
      } else {
        alert('not supported yet')
      }

    },
    formatTimeDuration(durationInMinutes) {
      return `${moment.duration(durationInMinutes * 60 * 1000).asHours().toFixed(1)} hours`;
    }
  },
  data() {
    return {
      dates: [],
      search: null,
      teamsSelected: null,
      showDatePicker: null,
      loading: null,
      datePickerDate: null,
      showGarnish: null,
      onlyGarnish: null,
      showStickers: null,
      printTask: null,
      mainCount: [],
      prepTaskWindow: null,
      stickerClass: {},
      isPrinting: null,
      denseView: true,
      showUpdateEstimate: null,
      updateTimeEstimate: null,
      tasks: []
    }
  },
  watch: {
    onlyGarnish(val) {
      if (val) {
        this.showGarnish = true;
        this.search = 'garnish';
      } else {
        this.showGarnish = false;
        this.search = this.search === 'garnish' ? '' : this.search;
      }
    },
    showStickers(v) {
      if (v) {
        this.prepTaskWindow = 1;
      } else {
        this.prepTaskWindow = 0;
      }
    }
  },
  props: {
    team: {type: String, required: true},
    teamFilter: {type: Array, default: null, required: false},
    showLateWarnings: {type: Boolean, default: false, required: false},
    sortFn: {type: Function, default: null, required: false},
    hideGarnish: {type: Boolean, default: false, required: false},
    shortsOnly: {type: Boolean, default: false, required: false},
    hideShorts: {type: Boolean, default: false, required: false},
    lateWarningPriorityDeadlines: {type: Object, default: null, required: false},
    canSetEstimate: {type: Boolean, default: false, required: false}
  }
}
</script>

<style scoped>

</style>
